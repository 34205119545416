import { useBehaviorPatternAnalysis } from '~/modules/tracking/app/behavior-pattern-analysis'
import { useTrackingGA } from '~/modules/tracking/ga/tracking-ga'

export default defineNuxtPlugin((nuxtApp) => {
  // 페이지 로드시 훅
  nuxtApp.hook('page:start', () => {})

  // 페이지 로드시 훅
  nuxtApp.hook('app:beforeMount', () => {
    // 방문자 데이터 수집
    useBehaviorPatternAnalysis().sendVisitorData()
    // 유저 데이터 ga tracking
    useTrackingGA().addUserDataLayer()
  })

  nuxtApp.hook('page:finish', () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  })
})
