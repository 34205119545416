export const TPostSort = {
  POPULAR: 'PO',
  LATEST: 'LA'
}
export type TPostSort = (typeof TPostSort)[keyof typeof TPostSort]

export const TPostType = {
  POPULAR: 'POPULAR',
  LATEST: 'LATEST',
  INTEREST: 'INTEREST'
}
export type TPostType = (typeof TPostType)[keyof typeof TPostType]

export const TBusinessSort = {
  POPULAR: 'PO',
  LATEST: 'LA'
}
export type TBusinessSort = (typeof TBusinessSort)[keyof typeof TBusinessSort]

export const SEARCH_TYPE = {
  CONTENTS: 'CONTENTS',
  BUSINESS: 'BUSINESS'
}
export type SEARCH_TYPE = (typeof SEARCH_TYPE)[keyof typeof SEARCH_TYPE]

export const INTEREST_TYPE = {
  BOOKMARK: 'BOOKMARK',
  FOLLOWING: 'FOLLOWING'
}
export type INTEREST_TYPE = (typeof INTEREST_TYPE)[keyof typeof INTEREST_TYPE]

export const AFFECT_TYPE = {
  HOME: 'HOME',
  CATEGORY: 'CATEGORY',
  POPULAR: 'POPULAR',
  LATEST: 'LATEST',
  INTEREST: 'INTEREST',
  RECOMMEND: 'RECOMMEND',
  REPORT: 'REPORT',
  SEARCH: 'SEARCH',
  BOOKMARK: 'BOOKMARK',
  FOLLOWING: 'FOLLOWING',
  CORP_PROFILE: 'CORP_PROFILE',
  SHARE: 'SHARE',
  UPLOAD: 'UPLOAD'
}
export type AFFECT_TYPE = (typeof AFFECT_TYPE)[keyof typeof AFFECT_TYPE]

export const SEARCH_AFFECT_TYPE = {
  Related: 'Related',
  Typing: 'Typing'
}
export type SEARCH_AFFECT_TYPE = (typeof SEARCH_AFFECT_TYPE)[keyof typeof SEARCH_AFFECT_TYPE]
