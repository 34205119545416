export const PATH_AUTH_CALLBACK_PAGE = '/member/auth/callbackOauth' // SNS 인증 callback
export const PATH_LOGIN_PAGE = '/member/login' // 로그인 페이지
export const PATH_JOIN_PAGE = '/member/join' // 회원가입 페이지
export const PATH_SUCCESS_LOGIN_PATH = '/' // 로그인 성공시 이동할 페이지
export const PATH_LOGIN_EMAIL = '/member/login/email' // 이메일 로그인 페이지

export default {
  PATH_AUTH_CALLBACK_PAGE,
  PATH_LOGIN_PAGE,
  PATH_JOIN_PAGE,
  PATH_SUCCESS_LOGIN_PATH,
  PATH_LOGIN_EMAIL
}
