export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"},{"hid":"og:title","property":"og:title","content":"오운드 OUND | 기업 오리지널 콘텐츠 플랫폼"},{"hid":"og:site_name","property":"og:site_name","content":"오운드 OUND"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:image","property":"og:image","content":"/thumbnail.png"},{"hid":"description","name":"description","content":"기업 미디어의 혁신 - 기업이 발행하는 오리지널 콘텐츠들이 모여있는 곳 오운드에서 기업 인사이트와 최신 뉴스를 확인하세요."},{"hid":"og:description","property":"og:description","content":"기업 미디어의 혁신 - 기업이 발행하는 오리지널 콘텐츠들이 모여있는 곳 오운드에서 기업 인사이트와 최신 뉴스를 확인하세요."},{"hid":"og:local","property":"og:local","content":"ko_KR"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"canonical","href":"https://www.ound.ai"}],"style":[],"script":[{"hid":"stripe","src":"https://cdn.jsdelivr.net/npm/swiper@9/swiper-element-bundle.min.js","defer":true},{"src":"https://cdn.iamport.kr/v1/iamport.js"},{"type":"text/javascript","src":"https://cdn.tiny.cloud/1/xxhqir4qe8f4df4f7zrr5k2xvhyej96dqlafzz0h9677povr/tinymce/6/tinymce.min.js","referrerpolicy":"origin"}],"noscript":[],"title":"오운드 OUND | 기업 오리지널 콘텐츠 플랫폼"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = false

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false