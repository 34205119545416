var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { prefixName } from '../types/types';
const renderType = 'render';
const elementName = `${prefixName}-${renderType}-shadow`;
let RenderShadowDomElement = class RenderShadowDomElement extends LitElement {
    constructor() {
        super();
        this.dom = "";
    }
    render() {
        return html `${unsafeHTML(this.dom)}`;
    }
};
__decorate([
    property({ type: String })
], RenderShadowDomElement.prototype, "dom", void 0);
RenderShadowDomElement = __decorate([
    customElement(elementName)
], RenderShadowDomElement);
export { RenderShadowDomElement };
