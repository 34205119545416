import { get } from '@tms/core'
import { StringUtil } from '@tms/utils'
export interface IGAUser {
  usertype: string;
  signuptype: string;
  userid: string;
  birth: string;
  gender: string;
}

export const AUTH_NAME_CODE = {
  AUTH_USER: 'reader',
  AUTH_BUSINESS: 'corporation',
  AUTH_BUSINESS_MASTER: 'corporation'
}
export type AUTH_NAME_CODE = (typeof AUTH_NAME_CODE)[keyof typeof AUTH_NAME_CODE];

export const CATEGORY_1 = {
  corporation: 'corporation',
  original: 'original'
}
export type CATEGORY_1 = (typeof CATEGORY_1)[keyof typeof CATEGORY_1];

export const CATEGORY_2 = {
  notice: 'notice',
  pressrelease: 'pressrelease',
  insight: 'insight',
  successstory: 'successstory',
  product: 'product',
  culture: 'culture',
  recruit: 'recruit',
  original: 'original',
  oundplus: 'oundplus'
}
export type CATEGORY_2 = (typeof CATEGORY_2)[keyof typeof CATEGORY_2];

export interface IGAProduct {
  content_id: string;
  content_name: string;
  category1: CATEGORY_1;
  category2: CATEGORY_2;
  interest: string;
  /** 그 외 항목 */
  [key: string]: any;
}

/** ga */
export const useTrackingGA = () => {
  const gtm = useGtm()

  /** ga4 variable */
  const GA_EVENT_VARIABLE = {
    ITEM_KEY: 'items',
    NO_DATA: 'none'
  }

  const GA_EVENT_NAME = {
    SINUP_UP: 'sign_up', // 회원가입 완료 화면 조회 시
    SELECT_CONTENT: 'Select_Content', // 콘텐츠 목록에서 콘텐츠를 선택했을 때
    VIEW_CONTENT: 'View_Content', // 콘텐츠를 조회할 때
    VIEW_CONTENT_LIST: 'View_ContentList', // 콘텐츠 목록을 조회할 때
    FOLLOW_CONTENT: 'Follow_Content', // 콘텐츠 상세에서 팔로우 버튼 클릭 시
    FOLLOW_CONTENT_LIST: 'Follow_ContentList', // 콘텐츠 상세페이지를 제외한 모든 팔로우.
    FOLLOW_CORP_PROFILE: 'Follow_CorpProfile', // 기업 프로필에서 팔로우 버튼 클릭 시
    BOOKMART_CONTENT: 'Bookmark_Content', // 콘텐츠 상세화면에서 북마크 버튼 클릭 시
    BOOKMART_CONTENT_LIST: 'Bookmark_ContentList', // 콘텐츠 목록에서 북마크 버튼 클릭 시
    UPLOAD_CONTENT: 'Upload_Content', // 콘텐츠 업로드 완료 시
    TEMPORARY_CONTENT: 'TemporaryContent' // 콘텐츠 임시저장 완료 시
  }

  const addDataLayer = (parameters: Partial<IGAProduct>[]) => {
    initDataLayer(GA_EVENT_VARIABLE.ITEM_KEY)
    if (window.dataLayer) {
      window.dataLayer.push({ items: parameters })
    }
  }

  const addDataLayerWithEvent = (eventName: string, parameters: Partial<IGAProduct>[] | any) => {
    gtm.trackEvent({ event: eventName, ...parameters })
  }

  const addUserDataLayer = async () => {
    try {
      const _accessToken = useCookie('tma').value
      if (StringUtil.isEmpty(_accessToken)) { return }

      const data = await getUserInfo()
      if (data !== null) {
        const userAuth = data?.userAuthName as keyof typeof AUTH_NAME_CODE
        const channel = data?.channel
        let birth = data?.birthday ?? GA_EVENT_VARIABLE.NO_DATA

        if (birth !== GA_EVENT_VARIABLE.NO_DATA) {
          birth = birth.substring(0, 4)
        }

        const gaData: IGAUser = {
          usertype: AUTH_NAME_CODE[userAuth],
          signuptype: channel?.toLowerCase(),
          userid: data?.identityId,
          birth,
          gender: data?.gender?.toLowerCase()
        }

        if (window.dataLayer) {
          window.dataLayer.push(gaData)
        }
      }
    } catch (error) {
      console.error('addUserDataLayer', error)
    }
  }

  const checkedIsEmptyDataLayer = () => {
    if (!window.dataLayer) {
      window.dataLayer = []
    }
  }

  const initDataLayer = (key: string) => {
    checkedIsEmptyDataLayer()

    const arr: any = window.dataLayer
    arr.forEach(function (value: any, index: number) {
      const objKey = Object.keys(value)
      if (key === objKey[0]) {
        arr.splice(index, 1)
      }
    })
  }

  const getUserInfo = async () => {
    try {
      const response = await get('/oauth/v1/token/profile')
      if (response.code === 0) {
        return response.data
      }
      return null
    } catch (error) {
      return null
    }
  }

  return {
    getUserInfo,
    addDataLayer,
    addUserDataLayer,
    addDataLayerWithEvent,
    GA_EVENT_VARIABLE,
    GA_EVENT_NAME
  }
}
