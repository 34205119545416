import '@tms/wc'
import { setBaseURL } from '@tms/core'
import { TTokenCookieName } from '@tms/authorization'
import type { AxiosError, AxiosRequestConfig } from 'axios' // '@tms/core' dependency

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const AUTH_BASE_URL: string = config.public.API_HOST

  // tms core 모듈의 공통 에러 핸들러를 등록한다
  const apiErrorHandler = (error: AxiosError) => {
    // 오류 공통 처리는 composable로 생성하여 처리한다
    const statusCode = error?.response?.status
    nuxtApp.callHook('app:error', {
      statusCode,
      message: error.message
    })
  }

  const requestHandeler = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const accessToken = useCookie(TTokenCookieName.accessToken)
    const sessionHeader = {
      _tma_token: accessToken.value ?? '',
      _tmr_token: useCookie(TTokenCookieName.refreshToken).value ?? ''
    }

    config.headers = {
      ...config.headers,
      ...sessionHeader
    }

    return config
  }

  const axiosOption = {
    errorHandlingCallback: apiErrorHandler,
    requestHandeler
  }

  setBaseURL(AUTH_BASE_URL, axiosOption)
})
