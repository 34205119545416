import { PATH_INVITE_CONFIRM, PATH_INVITE_JOIN } from '../config/manager-path'
export default defineNuxtRouteMiddleware(async (to) => {
  if (process.client) { return }

  const config = useRuntimeConfig()
  const query = to.query

  const key = query.key as string | undefined
  if (!key) {
    throw new Error('인증 코드가 없습니다')
  }

  // 담당자 체크 API 호출
  try {
    const response = await getUserInfoByKey(key!)
    if (response.code !== 0) {
      throw new Error(response.message)
    }

    const hasAccount = response.data.alreadyUser
    if (hasAccount) {
      // 관리자 메일이 가입되어 있을 경우
      return navigateTo({ path: PATH_INVITE_CONFIRM, query: { key } })
    }

    // 관리자 메일이 가입되어 있지 않을 경우
    return navigateTo({ path: PATH_INVITE_JOIN, query: { key } })
  } catch (error: any) {
    showError(error)
  }

  async function getUserInfoByKey (key: string) {
    const AUTH_BASE_URL: string = config.public.API_HOST
    const response = await fetch(
      `${AUTH_BASE_URL}/membership/v1/pwd/verify/business`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ key })
      }
    )
    const dataJson = await response.json()

    if (dataJson.code === 0) {
      return dataJson
    }
    return dataJson
  };
})
