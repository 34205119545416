import ClickOutside from 'vue-click-outside'
import { InputCondition } from '@tms/directive'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', ClickOutside)
  nuxtApp.vueApp.directive('input-condition', {
    beforeMount (el, binding) {
      InputCondition.inserted(el, binding)
    },
    unmounted (el, binding) {
      InputCondition.unbind(el, binding)
    }
  })
  nuxtApp.vueApp.directive('focus', {
    mounted: function (el) {
      nextTick(() => {
        el?.querySelector('input')?.focus()
      })
    }
  })
})
