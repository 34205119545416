var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css } from "lit";
import { customElement } from "lit/decorators.js";
import { RenderShadowDomElement } from "./render-shadow-dom";
import { prefixName } from '../types/types';
//css https://cdn.tiny.cloud/1/36w289t30ulcty6s126gi6wfahjpj97tm0owqsgbhn6teg2x/tinymce/6.5.1-19/skins/content/default/content.min.css
const renderType = 'render-editor';
const elementName = `${prefixName}-${renderType}-shadow`;
let RenderShadowDomEditorElement = class RenderShadowDomEditorElement extends RenderShadowDomElement {
};
RenderShadowDomEditorElement.styles = [
    css `
      :host{font-size: 14px; font-weight: 400; line-height: 1.5; color: #000; font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";}
      p { margin: 0 0px; padding: 0 0px; clear: both; }
      img{-webkit-user-drag: none;max-width: 100%;height:auto;}
      img:not([data-coupon-no]) {pointer-events: none;}
      iframe {max-width: 100%;}
      table { border-color: #ccc; }
      table td { padding: .4rem; }
      blockquote { border-left: 2px solid #ccc; margin-left: 1.5rem; padding-left: 1rem; }
      figure { margin: 0; }
      figure.align-left { float: left; }
      figure.align-right { float: right; }
      figure figcaption { color: #999; display: block; margin-top: .25rem; text-align: left; }
    `
];
RenderShadowDomEditorElement = __decorate([
    customElement(elementName)
], RenderShadowDomEditorElement);
export { RenderShadowDomEditorElement };
