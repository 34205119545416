import { PATH_LOGIN_PAGE } from '~/modules/member/common/config/member-path'
export default defineNuxtPlugin((nuxtApp) => {
  // Nuxt Server 구동 중에 발생한 오류 등 수신
  nuxtApp.hook('app:error', (error) => {
    if (error.statusCode === 401 || error.statusCode === 403) {
      clearError({ redirect: PATH_LOGIN_PAGE })
    }
  })
})

/**
 * 인증 에러 hook 호출
 * nuxtApp은 composiable문법을 이용하여 가져올 수 있습니다
 * ```
 * const nuxtApp = useNuxtApp()
 * callHookAuthError(nuxtApp)
 * ```
 */
export const callHookAuthError = (nuxtApp: any) => {
  nuxtApp.callHook('app:error', { statusCode: 401, message: 'Unauthorized' })
}
