<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
const jsonLd = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [{
    '@type': 'ListItem',
    position: 1,
    name: '인사이트',
    item: 'https://www.ound.ai/post/category/list/927854'
  }, {
    '@type': 'ListItem',
    position: 2,
    name: '성공사례',
    item: 'https://www.ound.ai/post/category/list/498245?sort=PO&timestamp=1725947014139'
  }, {
    '@type': 'ListItem',
    position: 3,
    name: '제품',
    item: 'https://www.ound.ai/post/category/list/532425?sort=PO&timestamp=1725947021869'
  }, {
    '@type': 'ListItem',
    position: 4,
    name: '기업문화',
    item: 'https://www.ound.ai/post/category/list/843534?sort=PO&timestamp=1725947034235'
  }, {
    '@type': 'ListItem',
    position: 5,
    name: '채용',
    item: 'https://www.ound.ai/post/category/list/234673?sort=PO&timestamp=1725947040883'
  }, {
    '@type': 'ListItem',
    position: 6,
    name: '공지',
    item: 'https://www.ound.ai/post/category/list/347247?sort=PO&timestamp=1725947047257'
  }, {
    '@type': 'ListItem',
    position: 7,
    name: '보도자료',
    item: 'https://www.ound.ai/post/category/list/243672?sort=PO&timestamp=1725947054731'
  }, {
    '@type': 'ListItem',
    position: 8,
    name: '오운드 오리지널',
    item: 'https://www.ound.ai/post/category/list/137623?sort=PO&timestamp=1725947063656'
  }, {
    '@type': 'ListItem',
    position: 9,
    name: '오운드 플러스',
    item: 'https://www.ound.ai/post/category/list/234793?sort=PO&timestamp=1725947068841'
  }]
}

useHead({
  script: [
    {
      type: 'application/ld-json',
      innerHTML: JSON.stringify(jsonLd)
    }
  ]
})
</script>
