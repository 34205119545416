import { StringGenerator, StringUtil, UserAgentInfo } from '@tms/utils'
export const useBehaviorPatternAnalysis = () => {
  const config = useRuntimeConfig()
  const ANALYSIS_ID_KEY = 'analysisid'
  const ANALYSIS_CHECK_GEUST = 'agid'
  const ANALYSIS_CHECK_USER = 'auid'
  const AUTH_COOKIE = 'tma'

  /** 쿠키 생성 옵션
   * 당일 23:59:59 만료
   */

  const getCookieOptinos = () => {
    const now = new Date()
    const expires = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59)

    return {
      path: '/',
      expires
    }
  }

  const _accessToken = () => {
    return useCookie(AUTH_COOKIE).value
  }

  // 회원 여부 확인
  const _isUser = () => {
    return !StringUtil.isEmpty(_accessToken())
  }

  // 비회원 로그 전송 여부 확인
  const _hasGuestCookie = () => {
    return !StringUtil.isEmpty(useCookie(ANALYSIS_CHECK_GEUST).value)
  }

  // 회원 로그 전송 여부 확인
  const _hasUserCookie = () => {
    return !StringUtil.isEmpty(useCookie(ANALYSIS_CHECK_USER).value)
  }

  /**
   * 분석용 쿠키
   * 1일 단위로 쿠키 생성
   */
  const _getAnalyticsId = () => {
    const getAnalyticsIdCookieValue = useCookie(ANALYSIS_ID_KEY).value
    const hasAnalysisId = !StringUtil.isEmpty(getAnalyticsIdCookieValue)

    if (!hasAnalysisId) {
      const uuid = StringGenerator.generateUUID()
      const options = getCookieOptinos()
      useCookie(ANALYSIS_ID_KEY, options).value = uuid
      return uuid
    }
    return getAnalyticsIdCookieValue
  }

  const getOffset = (): number => {
    const offset = new Date().getTimezoneOffset() / -60
    return offset
  }

  const _getUserAgentInfo = () => {
    const agent = new UserAgentInfo()
    return {
      deviceType: agent.deviceType,
      deviceOs: agent.os,
      browser: agent.browser,
      browserVer: agent.browserVersion,
      browserAgent: agent.userAgentString,
      osVersion: agent.osVersion,
      deviceId: '',
      pushToken: ''
    }
  }

  /**
   * 데이터 전송 시점 :당일 1회 전송, 단 시크릿 모드의 경우 브라우저 오픈 시마다 전송
   *
   * case1. 비회원으로 최초 진입 : 비회원 로그 전송
   * case2. 로그인으로 최초 진입 : 회원 로그 전송
   * case3. 비회원으로 최초 진입 후 로그인 : 비회원 로그 전송 & 로그인 후 회원 로그 전송
   * case4. 회원으로 최초 진입 후 로그아웃: 회원 로그 생성 (사용자가 새로고침시에는 case1 진행)
   * */

  const sendVisitorData = () => {
    const url = `${config.public.API_HOST}/visit/v1`

    const analysisid = _getAnalyticsId()
    const isUser = _isUser()

    if ((isUser && !_hasUserCookie()) || (!isUser && !_hasGuestCookie())) {
      const data = JSON.stringify({
        offset: getOffset(),
        accessToken: _accessToken(),
        tmaId: analysisid,
        ..._getUserAgentInfo()
      })
      const blob = new Blob([data], { type: 'application/json; charset=UTF-8' })
      navigator.sendBeacon(url, blob)
      if (isUser) {
        useCookie(ANALYSIS_CHECK_USER, getCookieOptinos()).value = analysisid
      } else {
        useCookie(ANALYSIS_CHECK_GEUST, getCookieOptinos()).value = analysisid
      }
    }
  }

  /**
   * 데이터 전송 시점 : 클릭시 전송
   * */

  const sendContentsClickData = (postSeq: number) => {
    const url = `${config.public.API_HOST}/click/v1`
    const data = JSON.stringify({
      postSeq,
      offset: getOffset(),
      tmsId: _getAnalyticsId(),
      accessToken: _accessToken()
    })
    const blob = new Blob([data], { type: 'application/json; charset=UTF-8' })

    navigator.sendBeacon(url, blob)
  }

  return { sendVisitorData, sendContentsClickData }
}
