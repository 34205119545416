import { default as b2b6XwZUrWxWKMeta } from "/apps/src/ound-front/apps/shop-web/pages/b2b.vue?macro=true";
import { default as _91id_93a0KmhqqyQjMeta } from "/apps/src/ound-front/apps/shop-web/pages/business/profile/[id].vue?macro=true";
import { default as listS4xwrtfHDlMeta } from "/apps/src/ound-front/apps/shop-web/pages/follow/list.vue?macro=true";
import { default as index7G4G5aWFQYMeta } from "/apps/src/ound-front/apps/shop-web/pages/index.vue?macro=true";
import { default as bookmark8LMm8Mz3EaMeta } from "/apps/src/ound-front/apps/shop-web/pages/interest/bookmark.vue?macro=true";
import { default as followinghjzvhvYGqQMeta } from "/apps/src/ound-front/apps/shop-web/pages/interest/following.vue?macro=true";
import { default as interestvMjAymFvadMeta } from "/apps/src/ound-front/apps/shop-web/pages/interest.vue?macro=true";
import { default as confirmjrLqCUR1HtMeta } from "/apps/src/ound-front/apps/shop-web/pages/manager/invite/confirm.vue?macro=true";
import { default as indexwqepUa91OuMeta } from "/apps/src/ound-front/apps/shop-web/pages/manager/invite/index.vue?macro=true";
import { default as joinZC7vKuZ9roMeta } from "/apps/src/ound-front/apps/shop-web/pages/manager/invite/join.vue?macro=true";
import { default as callbackCertifyBb9wciAgYyMeta } from "/apps/src/ound-front/apps/shop-web/pages/member/auth/callbackCertify.vue?macro=true";
import { default as callbackOauthRXBtsWLjMHMeta } from "/apps/src/ound-front/apps/shop-web/pages/member/auth/callbackOauth.vue?macro=true";
import { default as completeP60SB44jViMeta } from "/apps/src/ound-front/apps/shop-web/pages/member/join/complete.vue?macro=true";
import { default as email3LNnb4Etv2Meta } from "/apps/src/ound-front/apps/shop-web/pages/member/join/email.vue?macro=true";
import { default as index9AYN9YtbCaMeta } from "/apps/src/ound-front/apps/shop-web/pages/member/join/index.vue?macro=true";
import { default as profilepdZPIGERcPMeta } from "/apps/src/ound-front/apps/shop-web/pages/member/join/profile.vue?macro=true";
import { default as emailFWs5LsNep1Meta } from "/apps/src/ound-front/apps/shop-web/pages/member/login/email.vue?macro=true";
import { default as index3RPPGkBsLvMeta } from "/apps/src/ound-front/apps/shop-web/pages/member/login/index.vue?macro=true";
import { default as indexoVemNGqEEzMeta } from "/apps/src/ound-front/apps/shop-web/pages/mobile/category/index.vue?macro=true";
import { default as contentsElOlUyxX7ZMeta } from "/apps/src/ound-front/apps/shop-web/pages/mypage/business/analytics/contents.vue?macro=true";
import { default as outlineUTdHuQdU5hMeta } from "/apps/src/ound-front/apps/shop-web/pages/mypage/business/analytics/outline.vue?macro=true";
import { default as indexhCHFn6USeNMeta } from "/apps/src/ound-front/apps/shop-web/pages/mypage/business/index.vue?macro=true";
import { default as profilepMVKKJLe1rMeta } from "/apps/src/ound-front/apps/shop-web/pages/mypage/business/profile.vue?macro=true";
import { default as register8ITxwp9WosMeta } from "/apps/src/ound-front/apps/shop-web/pages/mypage/content/register.vue?macro=true";
import { default as index2XTzf95kecMeta } from "/apps/src/ound-front/apps/shop-web/pages/mypage/index.vue?macro=true";
import { default as registerbwHxkEAorIMeta } from "/apps/src/ound-front/apps/shop-web/pages/mypage/media-publish/register.vue?macro=true";
import { default as changeHaGcFgkmjoMeta } from "/apps/src/ound-front/apps/shop-web/pages/mypage/password/change.vue?macro=true";
import { default as check5EiK3xZ4ApMeta } from "/apps/src/ound-front/apps/shop-web/pages/mypage/password/check.vue?macro=true";
import { default as editGHrEj1h0yGMeta } from "/apps/src/ound-front/apps/shop-web/pages/mypage/user/edit.vue?macro=true";
import { default as withdrawygKupTgZ2AMeta } from "/apps/src/ound-front/apps/shop-web/pages/mypage/user/withdraw.vue?macro=true";
import { default as emailhvRpl4zM5SMeta } from "/apps/src/ound-front/apps/shop-web/pages/password/find/email.vue?macro=true";
import { default as optionsrv02ns8QFXMeta } from "/apps/src/ound-front/apps/shop-web/pages/password/find/options.vue?macro=true";
import { default as phone7rQjV92JbNMeta } from "/apps/src/ound-front/apps/shop-web/pages/password/find/phone.vue?macro=true";
import { default as _91type_9377UdPXY9fxMeta } from "/apps/src/ound-front/apps/shop-web/pages/password/reset/[type].vue?macro=true";
import { default as _91id_93SwEAxs5OCGMeta } from "/apps/src/ound-front/apps/shop-web/pages/post/[id].vue?macro=true";
import { default as _91id_93yET4uBQnIpMeta } from "/apps/src/ound-front/apps/shop-web/pages/post/category/list/[id].vue?macro=true";
import { default as listhWraWSrOCrMeta } from "/apps/src/ound-front/apps/shop-web/pages/post/interest/list.vue?macro=true";
import { default as listMRU5dqrfuHMeta } from "/apps/src/ound-front/apps/shop-web/pages/post/latest/list.vue?macro=true";
import { default as listP3uprY0kftMeta } from "/apps/src/ound-front/apps/shop-web/pages/post/popular/list.vue?macro=true";
import { default as _91id_938wA0ThXy3KMeta } from "/apps/src/ound-front/apps/shop-web/pages/post/recommend/list/[id].vue?macro=true";
import { default as listop9FRp2mL8Meta } from "/apps/src/ound-front/apps/shop-web/pages/post/report/list.vue?macro=true";
import { default as chartVIda6NhBLFMeta } from "/apps/src/ound-front/apps/shop-web/pages/sample/chart.vue?macro=true";
import { default as modalnfpQFUQtmyMeta } from "/apps/src/ound-front/apps/shop-web/pages/sample/modal.vue?macro=true";
import { default as page1nhAJgr8QhyMeta } from "/apps/src/ound-front/apps/shop-web/pages/sample/page1.vue?macro=true";
import { default as page2KyZkNjehFXMeta } from "/apps/src/ound-front/apps/shop-web/pages/sample/page2.vue?macro=true";
import { default as page4_errorPtDaLW4jaiMeta } from "/apps/src/ound-front/apps/shop-web/pages/sample/page4_error.vue?macro=true";
import { default as unsplashV4REyvI6nJMeta } from "/apps/src/ound-front/apps/shop-web/pages/sample/unsplash.vue?macro=true";
import { default as businessBsW80rvk0YMeta } from "/apps/src/ound-front/apps/shop-web/pages/search/business.vue?macro=true";
import { default as contentsvifN6ctLioMeta } from "/apps/src/ound-front/apps/shop-web/pages/search/contents.vue?macro=true";
import { default as search7Lm0NWD7EgMeta } from "/apps/src/ound-front/apps/shop-web/pages/search.vue?macro=true";
export default [
  {
    name: b2b6XwZUrWxWKMeta?.name ?? "b2b",
    path: b2b6XwZUrWxWKMeta?.path ?? "/b2b",
    meta: b2b6XwZUrWxWKMeta || {},
    alias: b2b6XwZUrWxWKMeta?.alias || [],
    redirect: b2b6XwZUrWxWKMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/b2b.vue").then(m => m.default || m)
  },
  {
    name: _91id_93a0KmhqqyQjMeta?.name ?? "business-profile-id",
    path: _91id_93a0KmhqqyQjMeta?.path ?? "/business/profile/:id()",
    meta: _91id_93a0KmhqqyQjMeta || {},
    alias: _91id_93a0KmhqqyQjMeta?.alias || [],
    redirect: _91id_93a0KmhqqyQjMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/business/profile/[id].vue").then(m => m.default || m)
  },
  {
    name: listS4xwrtfHDlMeta?.name ?? "follow-list",
    path: listS4xwrtfHDlMeta?.path ?? "/follow/list",
    meta: listS4xwrtfHDlMeta || {},
    alias: listS4xwrtfHDlMeta?.alias || [],
    redirect: listS4xwrtfHDlMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/follow/list.vue").then(m => m.default || m)
  },
  {
    name: index7G4G5aWFQYMeta?.name ?? "index",
    path: index7G4G5aWFQYMeta?.path ?? "/",
    meta: index7G4G5aWFQYMeta || {},
    alias: index7G4G5aWFQYMeta?.alias || [],
    redirect: index7G4G5aWFQYMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: interestvMjAymFvadMeta?.name ?? "interest",
    path: interestvMjAymFvadMeta?.path ?? "/interest",
    meta: interestvMjAymFvadMeta || {},
    alias: interestvMjAymFvadMeta?.alias || [],
    redirect: interestvMjAymFvadMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/interest.vue").then(m => m.default || m),
    children: [
  {
    name: bookmark8LMm8Mz3EaMeta?.name ?? "interest-bookmark",
    path: bookmark8LMm8Mz3EaMeta?.path ?? "bookmark",
    meta: bookmark8LMm8Mz3EaMeta || {},
    alias: bookmark8LMm8Mz3EaMeta?.alias || [],
    redirect: bookmark8LMm8Mz3EaMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/interest/bookmark.vue").then(m => m.default || m)
  },
  {
    name: followinghjzvhvYGqQMeta?.name ?? "interest-following",
    path: followinghjzvhvYGqQMeta?.path ?? "following",
    meta: followinghjzvhvYGqQMeta || {},
    alias: followinghjzvhvYGqQMeta?.alias || [],
    redirect: followinghjzvhvYGqQMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/interest/following.vue").then(m => m.default || m)
  }
]
  },
  {
    name: confirmjrLqCUR1HtMeta?.name ?? "manager-invite-confirm",
    path: confirmjrLqCUR1HtMeta?.path ?? "/manager/invite/confirm",
    meta: confirmjrLqCUR1HtMeta || {},
    alias: confirmjrLqCUR1HtMeta?.alias || [],
    redirect: confirmjrLqCUR1HtMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/manager/invite/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexwqepUa91OuMeta?.name ?? "manager-invite",
    path: indexwqepUa91OuMeta?.path ?? "/manager/invite",
    meta: indexwqepUa91OuMeta || {},
    alias: indexwqepUa91OuMeta?.alias || [],
    redirect: indexwqepUa91OuMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/manager/invite/index.vue").then(m => m.default || m)
  },
  {
    name: joinZC7vKuZ9roMeta?.name ?? "manager-invite-join",
    path: joinZC7vKuZ9roMeta?.path ?? "/manager/invite/join",
    meta: joinZC7vKuZ9roMeta || {},
    alias: joinZC7vKuZ9roMeta?.alias || [],
    redirect: joinZC7vKuZ9roMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/manager/invite/join.vue").then(m => m.default || m)
  },
  {
    name: callbackCertifyBb9wciAgYyMeta?.name ?? "member-auth-callbackCertify",
    path: callbackCertifyBb9wciAgYyMeta?.path ?? "/member/auth/callbackCertify",
    meta: callbackCertifyBb9wciAgYyMeta || {},
    alias: callbackCertifyBb9wciAgYyMeta?.alias || [],
    redirect: callbackCertifyBb9wciAgYyMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/member/auth/callbackCertify.vue").then(m => m.default || m)
  },
  {
    name: callbackOauthRXBtsWLjMHMeta?.name ?? "member-auth-callbackOauth",
    path: callbackOauthRXBtsWLjMHMeta?.path ?? "/member/auth/callbackOauth",
    meta: callbackOauthRXBtsWLjMHMeta || {},
    alias: callbackOauthRXBtsWLjMHMeta?.alias || [],
    redirect: callbackOauthRXBtsWLjMHMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/member/auth/callbackOauth.vue").then(m => m.default || m)
  },
  {
    name: completeP60SB44jViMeta?.name ?? "member-join-complete",
    path: completeP60SB44jViMeta?.path ?? "/member/join/complete",
    meta: completeP60SB44jViMeta || {},
    alias: completeP60SB44jViMeta?.alias || [],
    redirect: completeP60SB44jViMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/member/join/complete.vue").then(m => m.default || m)
  },
  {
    name: email3LNnb4Etv2Meta?.name ?? "member-join-email",
    path: email3LNnb4Etv2Meta?.path ?? "/member/join/email",
    meta: email3LNnb4Etv2Meta || {},
    alias: email3LNnb4Etv2Meta?.alias || [],
    redirect: email3LNnb4Etv2Meta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/member/join/email.vue").then(m => m.default || m)
  },
  {
    name: index9AYN9YtbCaMeta?.name ?? "member-join",
    path: index9AYN9YtbCaMeta?.path ?? "/member/join",
    meta: index9AYN9YtbCaMeta || {},
    alias: index9AYN9YtbCaMeta?.alias || [],
    redirect: index9AYN9YtbCaMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/member/join/index.vue").then(m => m.default || m)
  },
  {
    name: profilepdZPIGERcPMeta?.name ?? "member-join-profile",
    path: profilepdZPIGERcPMeta?.path ?? "/member/join/profile",
    meta: profilepdZPIGERcPMeta || {},
    alias: profilepdZPIGERcPMeta?.alias || [],
    redirect: profilepdZPIGERcPMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/member/join/profile.vue").then(m => m.default || m)
  },
  {
    name: emailFWs5LsNep1Meta?.name ?? "member-login-email",
    path: emailFWs5LsNep1Meta?.path ?? "/member/login/email",
    meta: emailFWs5LsNep1Meta || {},
    alias: emailFWs5LsNep1Meta?.alias || [],
    redirect: emailFWs5LsNep1Meta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/member/login/email.vue").then(m => m.default || m)
  },
  {
    name: index3RPPGkBsLvMeta?.name ?? "member-login",
    path: index3RPPGkBsLvMeta?.path ?? "/member/login",
    meta: index3RPPGkBsLvMeta || {},
    alias: index3RPPGkBsLvMeta?.alias || [],
    redirect: index3RPPGkBsLvMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/member/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexoVemNGqEEzMeta?.name ?? "mobile-category",
    path: indexoVemNGqEEzMeta?.path ?? "/mobile/category",
    meta: indexoVemNGqEEzMeta || {},
    alias: indexoVemNGqEEzMeta?.alias || [],
    redirect: indexoVemNGqEEzMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/mobile/category/index.vue").then(m => m.default || m)
  },
  {
    name: contentsElOlUyxX7ZMeta?.name ?? "mypage-business-analytics-contents",
    path: contentsElOlUyxX7ZMeta?.path ?? "/mypage/business/analytics/contents",
    meta: contentsElOlUyxX7ZMeta || {},
    alias: contentsElOlUyxX7ZMeta?.alias || [],
    redirect: contentsElOlUyxX7ZMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/mypage/business/analytics/contents.vue").then(m => m.default || m)
  },
  {
    name: outlineUTdHuQdU5hMeta?.name ?? "mypage-business-analytics-outline",
    path: outlineUTdHuQdU5hMeta?.path ?? "/mypage/business/analytics/outline",
    meta: outlineUTdHuQdU5hMeta || {},
    alias: outlineUTdHuQdU5hMeta?.alias || [],
    redirect: outlineUTdHuQdU5hMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/mypage/business/analytics/outline.vue").then(m => m.default || m)
  },
  {
    name: indexhCHFn6USeNMeta?.name ?? "mypage-business",
    path: indexhCHFn6USeNMeta?.path ?? "/mypage/business",
    meta: indexhCHFn6USeNMeta || {},
    alias: indexhCHFn6USeNMeta?.alias || [],
    redirect: indexhCHFn6USeNMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/mypage/business/index.vue").then(m => m.default || m)
  },
  {
    name: profilepMVKKJLe1rMeta?.name ?? "mypage-business-profile",
    path: profilepMVKKJLe1rMeta?.path ?? "/mypage/business/profile",
    meta: profilepMVKKJLe1rMeta || {},
    alias: profilepMVKKJLe1rMeta?.alias || [],
    redirect: profilepMVKKJLe1rMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/mypage/business/profile.vue").then(m => m.default || m)
  },
  {
    name: register8ITxwp9WosMeta?.name ?? "mypage-content-register",
    path: register8ITxwp9WosMeta?.path ?? "/mypage/content/register",
    meta: register8ITxwp9WosMeta || {},
    alias: register8ITxwp9WosMeta?.alias || [],
    redirect: register8ITxwp9WosMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/mypage/content/register.vue").then(m => m.default || m)
  },
  {
    name: index2XTzf95kecMeta?.name ?? "mypage",
    path: index2XTzf95kecMeta?.path ?? "/mypage",
    meta: index2XTzf95kecMeta || {},
    alias: index2XTzf95kecMeta?.alias || [],
    redirect: index2XTzf95kecMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/mypage/index.vue").then(m => m.default || m)
  },
  {
    name: registerbwHxkEAorIMeta?.name ?? "mypage-media-publish-register",
    path: registerbwHxkEAorIMeta?.path ?? "/mypage/media-publish/register",
    meta: registerbwHxkEAorIMeta || {},
    alias: registerbwHxkEAorIMeta?.alias || [],
    redirect: registerbwHxkEAorIMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/mypage/media-publish/register.vue").then(m => m.default || m)
  },
  {
    name: changeHaGcFgkmjoMeta?.name ?? "mypage-password-change",
    path: changeHaGcFgkmjoMeta?.path ?? "/mypage/password/change",
    meta: changeHaGcFgkmjoMeta || {},
    alias: changeHaGcFgkmjoMeta?.alias || [],
    redirect: changeHaGcFgkmjoMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/mypage/password/change.vue").then(m => m.default || m)
  },
  {
    name: check5EiK3xZ4ApMeta?.name ?? "mypage-password-check",
    path: check5EiK3xZ4ApMeta?.path ?? "/mypage/password/check",
    meta: check5EiK3xZ4ApMeta || {},
    alias: check5EiK3xZ4ApMeta?.alias || [],
    redirect: check5EiK3xZ4ApMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/mypage/password/check.vue").then(m => m.default || m)
  },
  {
    name: editGHrEj1h0yGMeta?.name ?? "mypage-user-edit",
    path: editGHrEj1h0yGMeta?.path ?? "/mypage/user/edit",
    meta: editGHrEj1h0yGMeta || {},
    alias: editGHrEj1h0yGMeta?.alias || [],
    redirect: editGHrEj1h0yGMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/mypage/user/edit.vue").then(m => m.default || m)
  },
  {
    name: withdrawygKupTgZ2AMeta?.name ?? "mypage-user-withdraw",
    path: withdrawygKupTgZ2AMeta?.path ?? "/mypage/user/withdraw",
    meta: withdrawygKupTgZ2AMeta || {},
    alias: withdrawygKupTgZ2AMeta?.alias || [],
    redirect: withdrawygKupTgZ2AMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/mypage/user/withdraw.vue").then(m => m.default || m)
  },
  {
    name: emailhvRpl4zM5SMeta?.name ?? "password-find-email",
    path: emailhvRpl4zM5SMeta?.path ?? "/password/find/email",
    meta: emailhvRpl4zM5SMeta || {},
    alias: emailhvRpl4zM5SMeta?.alias || [],
    redirect: emailhvRpl4zM5SMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/password/find/email.vue").then(m => m.default || m)
  },
  {
    name: optionsrv02ns8QFXMeta?.name ?? "password-find-options",
    path: optionsrv02ns8QFXMeta?.path ?? "/password/find/options",
    meta: optionsrv02ns8QFXMeta || {},
    alias: optionsrv02ns8QFXMeta?.alias || [],
    redirect: optionsrv02ns8QFXMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/password/find/options.vue").then(m => m.default || m)
  },
  {
    name: phone7rQjV92JbNMeta?.name ?? "password-find-phone",
    path: phone7rQjV92JbNMeta?.path ?? "/password/find/phone",
    meta: phone7rQjV92JbNMeta || {},
    alias: phone7rQjV92JbNMeta?.alias || [],
    redirect: phone7rQjV92JbNMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/password/find/phone.vue").then(m => m.default || m)
  },
  {
    name: _91type_9377UdPXY9fxMeta?.name ?? "password-reset-type",
    path: _91type_9377UdPXY9fxMeta?.path ?? "/password/reset/:type()",
    meta: _91type_9377UdPXY9fxMeta || {},
    alias: _91type_9377UdPXY9fxMeta?.alias || [],
    redirect: _91type_9377UdPXY9fxMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/password/reset/[type].vue").then(m => m.default || m)
  },
  {
    name: _91id_93SwEAxs5OCGMeta?.name ?? "post-id",
    path: _91id_93SwEAxs5OCGMeta?.path ?? "/post/:id()",
    meta: _91id_93SwEAxs5OCGMeta || {},
    alias: _91id_93SwEAxs5OCGMeta?.alias || [],
    redirect: _91id_93SwEAxs5OCGMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/post/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yET4uBQnIpMeta?.name ?? "post-category-list-id",
    path: _91id_93yET4uBQnIpMeta?.path ?? "/post/category/list/:id()",
    meta: _91id_93yET4uBQnIpMeta || {},
    alias: _91id_93yET4uBQnIpMeta?.alias || [],
    redirect: _91id_93yET4uBQnIpMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/post/category/list/[id].vue").then(m => m.default || m)
  },
  {
    name: listhWraWSrOCrMeta?.name ?? "post-interest-list",
    path: listhWraWSrOCrMeta?.path ?? "/post/interest/list",
    meta: listhWraWSrOCrMeta || {},
    alias: listhWraWSrOCrMeta?.alias || [],
    redirect: listhWraWSrOCrMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/post/interest/list.vue").then(m => m.default || m)
  },
  {
    name: listMRU5dqrfuHMeta?.name ?? "post-latest-list",
    path: listMRU5dqrfuHMeta?.path ?? "/post/latest/list",
    meta: listMRU5dqrfuHMeta || {},
    alias: listMRU5dqrfuHMeta?.alias || [],
    redirect: listMRU5dqrfuHMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/post/latest/list.vue").then(m => m.default || m)
  },
  {
    name: listP3uprY0kftMeta?.name ?? "post-popular-list",
    path: listP3uprY0kftMeta?.path ?? "/post/popular/list",
    meta: listP3uprY0kftMeta || {},
    alias: listP3uprY0kftMeta?.alias || [],
    redirect: listP3uprY0kftMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/post/popular/list.vue").then(m => m.default || m)
  },
  {
    name: _91id_938wA0ThXy3KMeta?.name ?? "post-recommend-list-id",
    path: _91id_938wA0ThXy3KMeta?.path ?? "/post/recommend/list/:id()",
    meta: _91id_938wA0ThXy3KMeta || {},
    alias: _91id_938wA0ThXy3KMeta?.alias || [],
    redirect: _91id_938wA0ThXy3KMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/post/recommend/list/[id].vue").then(m => m.default || m)
  },
  {
    name: listop9FRp2mL8Meta?.name ?? "post-report-list",
    path: listop9FRp2mL8Meta?.path ?? "/post/report/list",
    meta: listop9FRp2mL8Meta || {},
    alias: listop9FRp2mL8Meta?.alias || [],
    redirect: listop9FRp2mL8Meta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/post/report/list.vue").then(m => m.default || m)
  },
  {
    name: chartVIda6NhBLFMeta?.name ?? "sample-chart",
    path: chartVIda6NhBLFMeta?.path ?? "/sample/chart",
    meta: chartVIda6NhBLFMeta || {},
    alias: chartVIda6NhBLFMeta?.alias || [],
    redirect: chartVIda6NhBLFMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/sample/chart.vue").then(m => m.default || m)
  },
  {
    name: modalnfpQFUQtmyMeta?.name ?? "sample-modal",
    path: modalnfpQFUQtmyMeta?.path ?? "/sample/modal",
    meta: modalnfpQFUQtmyMeta || {},
    alias: modalnfpQFUQtmyMeta?.alias || [],
    redirect: modalnfpQFUQtmyMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/sample/modal.vue").then(m => m.default || m)
  },
  {
    name: page1nhAJgr8QhyMeta?.name ?? "sample-page1",
    path: page1nhAJgr8QhyMeta?.path ?? "/sample/page1",
    meta: page1nhAJgr8QhyMeta || {},
    alias: page1nhAJgr8QhyMeta?.alias || [],
    redirect: page1nhAJgr8QhyMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/sample/page1.vue").then(m => m.default || m)
  },
  {
    name: page2KyZkNjehFXMeta?.name ?? "sample-page2",
    path: page2KyZkNjehFXMeta?.path ?? "/sample/page2",
    meta: page2KyZkNjehFXMeta || {},
    alias: page2KyZkNjehFXMeta?.alias || [],
    redirect: page2KyZkNjehFXMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/sample/page2.vue").then(m => m.default || m)
  },
  {
    name: page4_errorPtDaLW4jaiMeta?.name ?? "sample-page4_error",
    path: page4_errorPtDaLW4jaiMeta?.path ?? "/sample/page4_error",
    meta: page4_errorPtDaLW4jaiMeta || {},
    alias: page4_errorPtDaLW4jaiMeta?.alias || [],
    redirect: page4_errorPtDaLW4jaiMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/sample/page4_error.vue").then(m => m.default || m)
  },
  {
    name: unsplashV4REyvI6nJMeta?.name ?? "sample-unsplash",
    path: unsplashV4REyvI6nJMeta?.path ?? "/sample/unsplash",
    meta: unsplashV4REyvI6nJMeta || {},
    alias: unsplashV4REyvI6nJMeta?.alias || [],
    redirect: unsplashV4REyvI6nJMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/sample/unsplash.vue").then(m => m.default || m)
  },
  {
    name: search7Lm0NWD7EgMeta?.name ?? "search",
    path: search7Lm0NWD7EgMeta?.path ?? "/search",
    meta: search7Lm0NWD7EgMeta || {},
    alias: search7Lm0NWD7EgMeta?.alias || [],
    redirect: search7Lm0NWD7EgMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: businessBsW80rvk0YMeta?.name ?? "search-business",
    path: businessBsW80rvk0YMeta?.path ?? "business",
    meta: businessBsW80rvk0YMeta || {},
    alias: businessBsW80rvk0YMeta?.alias || [],
    redirect: businessBsW80rvk0YMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/search/business.vue").then(m => m.default || m)
  },
  {
    name: contentsvifN6ctLioMeta?.name ?? "search-contents",
    path: contentsvifN6ctLioMeta?.path ?? "contents",
    meta: contentsvifN6ctLioMeta || {},
    alias: contentsvifN6ctLioMeta?.alias || [],
    redirect: contentsvifN6ctLioMeta?.redirect,
    component: () => import("/apps/src/ound-front/apps/shop-web/pages/search/contents.vue").then(m => m.default || m)
  }
]
  }
]