import type { ILoadingPlugin } from '~/modules/popup/loading/types/loading'

export default defineNuxtPlugin((nuxtApp) => {
  const loadingState = reactive({ isVisible: false })

  const loadingPlugin: ILoadingPlugin = {
    start () {
      loadingState.isVisible = true
    },
    end () {
      loadingState.isVisible = false
    }
  }

  nuxtApp.provide('loading', loadingPlugin)
  // 로딩 상태를 전역으로 제공
  nuxtApp.vueApp.provide('loadingState', loadingState)
})
