import { TTokenCookieName } from '@tms/authorization'
import { PATH_LOGIN_PAGE } from '~/modules/member/common/config/member-path'
import { useOauthStore } from '~/modules/member/auth/store/oauth-store'

interface IToken {
  accessToken: string;
  refreshToken: string;
}

interface ApiResponse {
  code: number;
  message: string;
}

interface ITokenResponse extends ApiResponse {
  data?: IToken;
}

const COOKIE_ACCESS_TOKEN = TTokenCookieName.accessToken
const COOKIE_REFRESH_TOKEN = TTokenCookieName.refreshToken

const PATH_FIRST_PAGE = '/'
// 로그인 체크 패턴
const CHECK_URL_PATTERN: string[] = ['/mypage/**', '/mypage']
const CHECK_URL_LOGIN: string[] = ['**/login', '**/login/**']

const convertPatternToRegex = (pattern: string): RegExp => {
  // Escape special regex characters except for the '**' wildcard, then replace '**' with a regex that matches any character sequence
  const regexPattern = pattern.replace(/[\-\[\]\/\{\}\(\)\+\?\.\\\^\$\|]/g, '\\$&').replace(/\*\*/g, '.*')
  return new RegExp('^' + regexPattern + '$')
}
// url 패턴 체크
const doesUrlMatchPatterns = (url: string, patterns: string[]): boolean => {
  return patterns.some((pattern) => {
    const regex = convertPatternToRegex(pattern)
    return regex.test(url)
  })
}

export const useValidAuth = () => {
  /**
   *
   * @param to router 이동할 정보
   */
  const authMiddleware = async (to: any) => {
    const toPath = to.path
    const isCheckedUrl = doesUrlMatchPatterns(toPath, CHECK_URL_PATTERN)
    const isLoginUrl = doesUrlMatchPatterns(toPath, CHECK_URL_LOGIN)

    if (isCheckedUrl || isLoginUrl) {
      if (isCheckedUrl) {
        const result = await validAuth()
        if (!result) {
          return createAuthError(to)
        }
      }

      const isLogin = hasAccessToken()
      if (isLoginUrl && isLogin) {
        const result = await validAuthLoginPage()
        if (result) {
          const { redirectUrl, clearRedirectUrl } = useOauthStore()
          clearRedirectUrl()
          if (redirectUrl) {
            const { pathname, search } = new URL(redirectUrl)
            return navigateTo(`${pathname}${search}`)
          }

          return navigateTo(PATH_FIRST_PAGE)
        }
      }
    } else {
      // 로그인 페이지로 이동할 필요 없는 그 외 페이지는 검증후 토큰만 삭제함
      validToken()
    }
  }

  const validToken = async () => {
    const accessToken = useCookie(COOKIE_ACCESS_TOKEN).value ?? ''
    const refreshToken = useCookie(COOKIE_REFRESH_TOKEN).value ?? ''
    const validToken = await isCheckedToken({
      _tma_token: accessToken,
      _tmr_token: refreshToken
    })

    if (!validToken) {
      clearAuthToken()
    }
  }

  const checkedCallApiTime = () => {
    const stateCheckTime = useState<number>('authExpired')
    const isChekced = new CheckedTimeClass().timeChecked(stateCheckTime.value)
    if (isChekced) {
      stateCheckTime.value = Date.now()
      return false
    }

    return true
  }

  /** 토큰 검증 api 호출 */
  async function TokenCheckAPI (headers: object) {
    const config = useRuntimeConfig()

    const apiHost: string = config.public.API_HOST
    const response = await fetch(`${apiHost}/oauth/v1/token/check`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      }
    })
    const dataJson = await response.json()

    if (dataJson.code === 0) {
      return dataJson
    }
    return dataJson
  }

  const isCheckedToken = async (headers: { _tma_token: string; _tmr_token: string }): Promise<Boolean> => {
    // 현재 시간과 마지막으로 토큰 체크한 시간이 10분 이내면 재조회 하지 않음
    const isCallApiTime = checkedCallApiTime()
    if (isCallApiTime) {
      return true
    }

    const resultToken: ITokenResponse = await TokenCheckAPI(headers)

    if (!resultToken.data?.accessToken) {
      return false
    }

    setAuthToken(resultToken.data!)

    return true
  }

  /**
   * 인증 오류 에러 발생
   */
  const createAuthError = (to: any) => {
    clearAuthToken()
    const path = to.fullPath
    const config = useRuntimeConfig()
    const domain: string = config.public.SHOP_HOST
    const redirectUrl = `${domain}${path}`

    return navigateTo({ path: PATH_LOGIN_PAGE, query: { redirectUrl } })
  }

  /**
   * 인증 토큰 세팅
   */
  const setAuthToken = (tokens: IToken) => {
    const config = useRuntimeConfig()
    if (!tokens.accessToken) {
      return
    }

    const options = {
      domain: config.public.COOKIE_DOMAIN
    }
    useCookie(COOKIE_ACCESS_TOKEN, options).value = tokens.accessToken
    useCookie(COOKIE_REFRESH_TOKEN, options).value = tokens.refreshToken
  }

  /**
   * 인증 토큰 삭제
   */
  const clearAuthToken = () => {
    const config = useRuntimeConfig()
    const options = {
      domain: config.public.COOKIE_DOMAIN
    }
    useCookie(COOKIE_ACCESS_TOKEN, options).value = null
    useCookie(COOKIE_REFRESH_TOKEN, options).value = null
  }

  /**
   * 로그인 페이지 인증 체크
   * 이미 로그인 되어있는 경우 첫 페이지로 진입한다
   */
  const hasAccessToken = () => {
    const accessToken = useCookie(COOKIE_ACCESS_TOKEN).value ?? ''
    if (accessToken) {
      return true
    }
    return false
  }

  const validAuthLoginPage = async () => {
    const accessToken = useCookie(COOKIE_ACCESS_TOKEN).value ?? ''
    const refreshToken = useCookie(COOKIE_REFRESH_TOKEN).value ?? ''
    const validToken = await isCheckedToken({
      _tma_token: accessToken,
      _tmr_token: refreshToken
    })

    if (!validToken) {
      return false
    }

    return true
  }

  /**
   * 로그인 검증
   */
  const validAuth = async () => {
    const accessToken = useCookie(COOKIE_ACCESS_TOKEN).value ?? ''
    const refreshToken = useCookie(COOKIE_REFRESH_TOKEN).value ?? ''
    if (!accessToken) {
      return false
    }

    const validToken = await isCheckedToken({
      _tma_token: accessToken,
      _tmr_token: refreshToken
    })
    if (!validToken) {
      return false
    }

    return true
  }

  /**
   * 로그인 검증
   */
  const checkedAuth = async () => {
    const accessToken = useCookie(COOKIE_ACCESS_TOKEN).value ?? ''
    const refreshToken = useCookie(COOKIE_REFRESH_TOKEN).value ?? ''
    if (!accessToken) {
      return false
    }

    return await isCheckedToken({
      _tma_token: accessToken,
      _tmr_token: refreshToken
    })
  }

  return {
    authMiddleware,
    validAuth,
    checkedAuth
  }
}

/**
 * 시간 비교를 위한 calss
 */
class CheckedTimeClass {
  public timeChecked = (checkedTime: number) => {
    if (!checkedTime) {
      return true
    }
    const currentTime = Date.now()
    if (!checkedTime || (checkedTime && currentTime - checkedTime >= 10 * 60 * 1000)) {
      return true
    }
    return false
  }
}
