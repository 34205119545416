import { defineStore } from 'pinia'

export const useOauthStore = defineStore('oauthStore', {
  persist: {
    storage: persistedState.sessionStorage
  },
  state: () => ({
    _code: 0 as number,
    _authToken: '' as string,
    _channel: '' as string,
    _redirectUrl: '' as string
  }),
  actions: {
    setOauthInfo (authData: { authToken: string; channel: string; redirectUrl?: string }, code: number) {
      this._authToken = authData.authToken
      this._channel = authData.channel
      this._code = code
    },
    setRedirectUrl (redirectUrl: string) {
      this._redirectUrl = redirectUrl
    },
    clearOauthInfo () {
      this._authToken = ''
      this._channel = ''
      this._code = 0
    },
    clearRedirectUrl () {
      this._redirectUrl = ''
    }
  },
  getters: {
    code: state => state._code,
    authToken: state => state._authToken,
    channel: state => state._channel,
    redirectUrl: state => state._redirectUrl
  }
})
