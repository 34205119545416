<template>
  <div class="content sub">
    <div class="inner_box">
      <div class="error_box">
        <div class="ico">
          <i class="icon_font_warning" />
        </div>
        <p class="font_h6 color_text mt24">
          {{ errorMessage.message }}
        </p>
        <p class="font_p4 mt4">
          {{ errorMessage.description }}
        </p>

        <div class="btn_center mt60">
          <button v-if="checkedTryAgain" type="button" class="btn_ty_01" @click="tryAgain">
            다시 시도하기
          </button>
          <button class="btn_ty_01" @click="toHome">
            홈으로 이동하기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface IPropsError {
  url: string;
  statusCode: number;
  statusMessage: string;
  message: string;
  description: string;
  data: any;
}
interface IProps {
 error: IPropsError
}

const { error } = defineProps<IProps>()
const errorMessage = computed(() => getMessage())
const checkedTryAgain = computed(() => !(error.statusCode === 101 || error.statusCode === 404))
const getMessage = () => {
  if (error.statusCode === 404) {
    return {
      message: '페이지를 찾을 수 없습니다',
      description: ''
    }
  } else if (error.statusCode === 101) {
    return {
      message: '존재하지 않는 콘텐츠입니다',
      description: ''
    }
  }

  return {
    message: error.message,
    description: error.description
  }
}

const tryAgain = () => {
  clearError()
  window.location.reload()
}

const toHome = () => {
  clearError({ redirect: '/' })
}
</script>
<style scoped>
    .error_box{padding:360px 0; text-align:center;}
    .error_box .icon_font_warning:before{font-size:31.77px; color:var(--color-icon-error);}

    @media screen and (max-width: 768px) {
        .error_box{padding:200px 0;}
    }
</style>
