import { TPostSort } from '../types/type'

export default defineNuxtRouteMiddleware((to) => {
  const query = to.query

  const sort = query.sort as string | undefined
  if (!sort || (!isPopular(sort!) && !isLatest(sort!))) {
    return navigateTo({ path: to.path, query: { ...query, sort: TPostSort.POPULAR } })
  }
})

const isPopular = (sort: string) => sort === TPostSort.POPULAR
const isLatest = (sort: string) => sort === TPostSort.LATEST
